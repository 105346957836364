<template>
  <main>
    <div class="header">
      <h1 class="heading">{{ $t('objects["Новый объект"]') }}</h1>
    </div>
    <v-form class="timeline" ref="form" v-model="valid" v-if="!isMobile&&!dialog_template">
      <div class="timeline__item timeline__item_hotel">
        <h2 class="timeline__label">{{ $t('objects["Данные объекта"]') }}</h2>
        <div class="timeline__value">
          <div class="field">
            <div class="control">
              <v-text-field
                solo
                outlined
                flat
                :label="$t('objects[\'Название\']')"
                :rules="nameRules"
                v-model="object.name"
              ></v-text-field>
            </div>
          </div>
          <div class="field">
            <div class="control">
              <v-text-field
                solo
                outlined
                flat
                :placeholder="$t('objects[\'Адрес\']')"
                :rules="addressRules"
                v-model="object.address"
              ></v-text-field>
            </div>
          </div>
          <div class="field">
            <div class="control">
              <p class="tip">
                {{
                  $t(
                    'objects["После добавления нового объекта стоимость подписки изменится"]'
                  )
                }}
              </p>
            </div>
          </div>
          <div class="field" v-if="$store.state.profile.is_agency">
            <div class="control" style="display: flex;align-items:center;margin-bottom: 15px;">
              <label style="margin-bottom: 0;display: block;margin-right: 15px;">Use slots as payment methods</label>
              <v-switch style="margin: 0" hide-details v-model="useSlots"></v-switch>
            </div>
            <div v-if="useSlots">
              <div style="display: flex;flex-direction: column;">
                <div>
                  <label style="margin-bottom: 0;display: block;margin-right: 15px;">Available standart slots: {{countOfStandart}}</label>
                </div>
                <div>
                  <label style="margin-bottom: 0;display: block;margin-right: 15px;">Available profi slots: {{countOfProfi}}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="timeline__item timeline__item_tariff">
        <h2 class="timeline__label">{{ $t('objects["Тарифный план"]') }}</h2>
        <div class="timeline__value">
          <div class="field">
            <ObjectTariff @changeTariff="changeTariff" />
          </div>
          <div class="field">
            <div class="control">
              <v-btn
                  class="object__submit btn btn_primary"
                  color="primary"
                  small
                  outlined
                  dense
                  @click="validate"
              >
                {{ $t('objects["Создать отель"]') }}
              </v-btn>

              <!--                            <PopupEmail ref="PopupEmail" :title="$t('popup.tariff_email', {email: 'me@bs.hr'})" />-->

              <v-dialog
                  max-width="700px"
                  height="400px"
                  v-model="dialog"
                  @click:outside="close"
              >
                <div class="popup__content popup__content_email" style="padding-left:50px;">
                  <p v-if="+tariff_id === 3">Tariff plan: <b>PROFI</b></p>
                  <p v-if="+tariff_id === 2">Tariff plan: <b>STANDART</b></p>
                  <p v-if="+tariff_id === 3">Price: <b>145$</b> per month</p>
                  <p v-if="+tariff_id === 2">Price: <b>70$</b> per month</p>
                  <v-btn class="popup__close"
                         small icon @click="close">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <label>Card Information</label>
                  <div
                      style="display:flex;margin-top: 10px;align-items: center;"
                  >
                    <div
                        id="card-number"
                        style="font-size:1.2em;border: 1px solid #ccc;width:100%;padding:10px 7px;border-top-right-radius: 5px;border-top-left-radius: 5px;"
                    ></div>
                    <!--/*                                 <div style="width: 20%;float:right;text-align:center;"><img src="https://js.stripe.com/v3/fingerprinted/img/visa-365725566f9578a9589553aa9296d178.svg" alt="visa"/><img src="https://js.stripe.com/v3/fingerprinted/img/mastercard-4d8844094130711885b5e41b28c9848f.svg" alt="mastercard"/><img src="https://js.stripe.com/v3/fingerprinted/img/unionpay-8a10aefc7295216c338ba4e1224627a1.svg" alt="unionpay"/><img src="https://js.stripe.com/v3/fingerprinted/img/amex-a49b82f46c5cd6a96a6e418a6ca1717c.svg" alt="amex"/></div>*/-->
                  </div>
                  <div style="display:flex;margin-top: -1px;">
                    <div
                        id="card-expiry"
                        style="border: 1px solid #ccc;width:50%;padding:10px 7px;border-bottom-left-radius: 5px;"
                    ></div>
                    <div
                        id="card-cvc"
                        style="border: 1px solid #ccc;width:50%;padding:10px 7px;border-bottom-right-radius: 5px;"
                    ></div>
                  </div>
                  <div
                      id="card-error"
                      v-if="err_stripe"
                      style="display: flex;align-items: center;margin-top:20px;"
                  >
                    <v-icon style="margin-right: 20px;" color="red"
                    >mdi-alert-circle</v-icon
                    >
                    {{ err_stripe }}
                  </div>
                  <div style="display: flex;justify-content: center;margin:40px 0;align-items: center;">

                    <v-btn
                        style="width:130px;min-width:130px;height:40px;"
                        right
                        class="object__submit btn btn_primary"
                        color="primary"
                        small
                        outlined
                        dense
                        @click="createToken"
                        v-if="!loading"
                    >Pay</v-btn
                    >
                    <v-btn
                        v-else
                        style="width:130px;min-width:130px;height:40px;"
                        right
                        class="object__submit btn btn_primary"
                        color="primary"
                        small
                        outlined
                        dense
                        @click="createToken"
                        disabled
                    ><v-progress-circular
                        style="margin-right: 5px;"
                        indeterminate
                    />
                      Wait</v-btn
                    >
                  </div>
                  <div style="display: flex;justify-content: center;margin-bottom: 30px;max-width:100%;align-items:center;">
                    <div style="max-width:200px;display:flex;align-items:center;margin-right: 15px;">
                      <img src="@/assets/img/stripe.png" alt="">
                    </div>
                    <div style="max-width:200px;height:56.6px;display:flex;align-items:center;">
                      <img src="@/assets/img/cards.png" alt="">
                    </div>
                  </div>
                  <div style="text-align: center;margin-top:40px;">
                    If you want to pay by wire transfer <br> please contact with us by email: <a href="mailto:anna@bs.hr">anna@bs.hr</a>
                  </div>
                </div>
              </v-dialog>
            </div>
          </div>
        </div>
      </div>
    </v-form>
    <v-form ref="form" v-model="valid" v-if="isMobile&&!dialog_template">
      <div class="timeline__item_hotel" style="width: 100%;">
        <h2 style="width: 100%;padding-left: 15px;margin-bottom:15px;">{{ $t('objects["Данные объекта"]') }}</h2>
        <div class="timeline__value" style="width: 100%;">
          <div class="field">
            <div class="control">
              <v-text-field
                  solo
                  outlined
                  flat
                  :label="$t('objects[\'Название\']')"
                  :rules="nameRules"
                  v-model="object.name"
              ></v-text-field>
            </div>
          </div>
          <div class="field">
            <div class="control">
              <v-text-field
                  solo
                  outlined
                  flat
                  :placeholder="$t('objects[\'Адрес\']')"
                  :rules="addressRules"
                  v-model="object.address"
              ></v-text-field>
            </div>
          </div>
          <div class="field">
            <div class="control">
              <p class="tip">
                {{
                $t(
                'objects["После добавления нового объекта стоимость подписки изменится"]'
                )
                }}
              </p>
            </div>
          </div>
          <div class="field" v-if="$store.state.profile.is_agency">
            <div class="control" style="display: flex;margin-bottom: 15px;">
              <label style="margin-bottom: 0;display: block;margin-right: 15px;">Use slots as payment methods</label>
              <v-switch style="margin: 0" hide-details v-model="useSlots"></v-switch>
            </div>
            <div v-if="useSlots">
              <div style="display: flex;flex-direction: column;">
                <div>
                  <label style="margin-bottom: 0;display: block;margin-right: 15px;">Available standart slots: {{countOfStandart}}</label>
                </div>
                <div>
                  <label style="margin-bottom: 0;display: block;margin-right: 15px;">Available profi slots: {{countOfProfi}}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="timeline__item_tariff" style="width: 100%;">
        <h2 style="width: 100%;padding-left: 15px;margin-bottom:15px;">{{ $t('objects["Тарифный план"]') }}</h2>
        <div class="timeline__value" style="width: 100%;">
          <div class="field">
            <ObjectTariff @changeTariff="changeTariff" />
          </div>
          <div class="field" style="margin-bottom: 80px;">
            <div class="control">
              <v-btn
                  class="object__submit btn btn_primary"
                  color="primary"
                  small
                  outlined
                  dense
                  @click="validate"
              >
                {{ $t('objects["Создать отель"]') }}
              </v-btn>

              <!--                            <PopupEmail ref="PopupEmail" :title="$t('popup.tariff_email', {email: 'me@bs.hr'})" />-->

              <v-dialog
                  max-width="700px"
                  height="400px"
                  v-model="dialog"
                  @click:outside="close"
              >
                <div class="popup__content popup__content_email" style="padding-left:50px;">
                  <p v-if="+tariff_id === 3">Tariff plan: <b>PROFI</b></p>
                  <p v-if="+tariff_id === 2">Tariff plan: <b>STANDART</b></p>
                  <p v-if="+tariff_id === 3">Price: <b>145$</b> per month</p>
                  <p v-if="+tariff_id === 2">Price: <b>70$</b> per month</p>
                  <v-btn class="popup__close"
                         small icon @click="close">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <label>Card Information</label>
                  <div
                      style="display:flex;margin-top: 10px;align-items: center;"
                  >
                    <div
                        id="card-number"
                        style="font-size:1.2em;border: 1px solid #ccc;width:100%;padding:10px 7px;border-top-right-radius: 5px;border-top-left-radius: 5px;"
                    ></div>
                    <!--/*                                 <div style="width: 20%;float:right;text-align:center;"><img src="https://js.stripe.com/v3/fingerprinted/img/visa-365725566f9578a9589553aa9296d178.svg" alt="visa"/><img src="https://js.stripe.com/v3/fingerprinted/img/mastercard-4d8844094130711885b5e41b28c9848f.svg" alt="mastercard"/><img src="https://js.stripe.com/v3/fingerprinted/img/unionpay-8a10aefc7295216c338ba4e1224627a1.svg" alt="unionpay"/><img src="https://js.stripe.com/v3/fingerprinted/img/amex-a49b82f46c5cd6a96a6e418a6ca1717c.svg" alt="amex"/></div>*/-->
                  </div>
                  <div style="display:flex;margin-top: -1px;">
                    <div
                        id="card-expiry"
                        style="border: 1px solid #ccc;width:50%;padding:10px 7px;border-bottom-left-radius: 5px;"
                    ></div>
                    <div
                        id="card-cvc"
                        style="border: 1px solid #ccc;width:50%;padding:10px 7px;border-bottom-right-radius: 5px;"
                    ></div>
                  </div>
                  <div
                      id="card-error"
                      v-if="err_stripe"
                      style="display: flex;align-items: center;margin-top:20px;"
                  >
                    <v-icon style="margin-right: 20px;" color="red"
                    >mdi-alert-circle</v-icon
                    >
                    {{ err_stripe }}
                  </div>
                  <div style="display: flex;justify-content: flex-end;">

                    <v-btn
                        style="width:130px;min-width:130px;height:40px;margin-top:40px;"
                        right
                        class="object__submit btn btn_primary"
                        color="primary"
                        small
                        outlined
                        dense
                        @click="createToken"
                        v-if="!loading"
                    >Pay by card</v-btn
                    >
                    <v-btn
                        v-else
                        style="width:130px;min-width:130px;height:40px;margin-top:40px;"
                        right
                        class="object__submit btn btn_primary"
                        color="primary"
                        small
                        outlined
                        dense
                        @click="createToken"
                        disabled
                    ><v-progress-circular
                        style="margin-right: 5px;"
                        indeterminate
                    />
                      Wait</v-btn
                    >
                  </div>
                  <div style="text-align: center;margin-top:40px;">
                    If you want to pay by wire transfer <br> please contact with us by email: <a href="mailto:anna@bs.hr">anna@bs.hr</a>
                  </div>
                </div>
              </v-dialog>
            </div>
          </div>
        </div>
      </div>
    </v-form>

    <div class="loading" v-if="loader">
      <img class="loading__icon" src="@/assets/img/logo__loader.svg" alt="" style="height:100px;width:100px;margin-bottom:50px;">
      <div>Please wait a little bit.</div>
      <div>We are creating your project :)</div>
    </div>
    <div v-if="dialog_template">
      <PopupTemplates :id="$store.state.objects.item.id" />
    </div>
    <PopupSuccess ref="PopupSuccess" title="Thank you!" :content="`We received money and activated your tariff plan: ${textTariff}`" />
  </main>
</template>
<script>
import ObjectTariff from "@/components/ObjectTariff.vue";
import titleMixin from "@/mixins/titleMixin";
import { mapState } from "vuex";
import PopupSuccess from "@/components/Popup/PopupSuccess.vue"
import axios from "axios"
import AgencyService from "../../services/agency.service";
export default {
  name: "Objects",
  components: {
    ObjectTariff,
    PopupSuccess,
    PopupTemplates: () => import("@/components/Popup/PopupTemplates.vue")
  },
  title() {
    return `Create Hotel`;
  },

  mixins: [titleMixin],
  data() {
    return {
      dialog_template:false,
      isMobile:false,
      useSlots:false,
      dialog: false,
      loading: false,
      loader: false,
      err_stripe: "",
      valid: false,
      nameRules: [(v) => !!v || this.$t('objects["Название обязательно"]')],
      addressRules: [(v) => !!v || this.$t('objects["Адрес обязателен"]')],
      object: {
        name: null,
        address: null,
        tariff_id: 1,
        lang: this.$route.params.lang || "en",
        pwa: false,
      },
      tariff_id: 1,
      token: null,
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      id: null,
      availableSlots:[]
    };
  },
  methods: {
    closeDialogTemplate(){
      this.successMessage = this.object.tariff_id !== 1 ? `We received money and activated your tariff plan: ${this.textTariff}` : 'Your property created successfully!'
      this.$refs.PopupSuccess.open();
    },
    async createToken() {
      this.loading = true;
      let clientSecret;
      await this.$axios
        .get("/get-intent")
        .then((data) => {
          clientSecret = data.data.client_secret;
        })
        .catch((err) => {
          this.err_stripe = err.message;
          this.loading = false;
        });
      const { setupIntent, error } = await this.$stripe.confirmCardSetup(
        clientSecret,
        {
          payment_method: { card: this.cardNumber },
        }
      );
      if (error) {
        this.err_stripe = error.message;
        this.loading = false;
      } else {
        await this.$axios
          .post("/set-subscribe", {
            hotel_id: this.$store.state.objects.item.id,
            plan_id: this.tariff_id,
            setupIntent_payment_method: setupIntent.payment_method,
            setupIntent_id: setupIntent.id,
          })
          .then(async (data) => {
            this.object.tariff_id = this.tariff_id;
            let dataObj = { ...this.object, id: this.$store.state.objects.item.id, pwa: false };
            await this.$store.dispatch("objects/edit", dataObj);
            this.dialog = false;
            this.loading = false;
            this.dialog_template = true;
          })
          .catch((err) => {
            this.err_stripe = err.message;
            this.loading = false;
          });
      }
    },
    close() {
      this.dialog = false;
    },
    objectCreate(e) {
      e.preventDefault();
    },
    async validate() {
      this.$refs.form.validate();
      if (this.valid) {
        this.loader = true;
        // await this.$axios.post(`admin/change-file-content`,{file_name:"advanced",data:this.landing.advanced});
        // await this.$axios.post(`admin/change-file-content`,{file_name:"branding",data:this.landing.branding});
        // await this.$axios.post(`admin/change-file-content`,{file_name:"menu",data:this.landing.menu});
        // await this.$axios.post(`admin/change-file-content`,{file_name:"order",data:this.landing.order});
        // await this.$axios.post(`admin/change-file-content`,{file_name:"order_form",data:this.landing.order_form});
        // await this.$axios.post(`admin/change-file-content`,{file_name:"reviews",data:this.landing.reviews});
        // await this.$axios.post(`admin/change-file-content`,{file_name:"socials",data:this.landing.socials});
        // await this.$axios.post(`admin/change-file-content`,{file_name:"advanced",data:this.landing.advanced});
        if (this.tariff_id === 1) {
          await this.$store
            .dispatch("objects/create", {
              ...this.object,
              tariff_id: +this.tariff_id,
            })
          this.loader = false;
          this.dialog_template = true;
        }
        else {
          if(this.useSlots){
            const data = await AgencyService.createHotel({...this.object,tariff_id:+this.tariff_id,payment_type: "slot"});
            this.object.id = data;
            this.$store.commit("objects/setItem", this.$store.state.objects.object);
            this.$store.commit("objects/setStatus", "create");
            this.loader = false;
            this.dialog_template = true;
          }
          else{
            await this.$store
              .dispatch("objects/create", { ...this.object, tariff_id: 1 })
            this.dialog = true;
            this.$nextTick(() => {
              const style = {
                base: {
                  color: "#000000",
                  fontSize: "1.1em",
                },
                invalid: {
                  color: "#fa755a",
                  iconColor: "#fa755a",
                },
              };
              this.cardNumber = this.stripeElements.create("cardNumber", {
                style,
              });
              this.cardNumber.mount("#card-number");
              this.cardExpiry = this.stripeElements.create("cardExpiry", {
                style,
              });
              this.cardExpiry.mount("#card-expiry");
              this.cardCvc = this.stripeElements.create("cardCvc", { style });
              this.cardCvc.mount("#card-cvc");
              this.cardNumber.on("change", (event) => {
                this.err_stripe = "";
              });
              this.cardExpiry.on("change", (event) => {
                this.err_stripe = "";
              });
              this.cardCvc.on("change", (event) => {
                this.err_stripe = "";
              });
              this.loader = false;
            });
          }
        }
      }
      else {
        this.$nextTick(() => {
          const el = this.$el.querySelector(
            ".v-messages.error--text:first-of-type"
          );
          this.$vuetify.goTo(el, {
            offset: 100,
          });
          return;
        });
      }
    },
    changeTariff(tariff_id) {
      this.tariff_id = tariff_id;
    },
  },
  computed: {
    ...mapState(["landing"]),
    stripeElements() {
      return this.$stripe.elements();
    },
    textTariff(){
      if(this.tariff_id === 1){
        return 'Free'
      }else if(this.tariff_id === 2){
        return 'Standart'
      }else{
        return 'Profi'
      }
    },
    countOfStandart(){
      const candidate = this.availableSlots.find(x => x.plan_id === 2);
      if(candidate){
        return candidate.quantity
      }else{
        return 0
      }
    },
    countOfProfi(){
      const candidate = this.availableSlots.find(x => x.plan_id === 3);
      if(candidate){
        return candidate.quantity
      }else{
        return 0
      }
    }
  },
  async mounted() {
    if(this.$store.state.profile.is_agency){
      this.availableSlots = await AgencyService.getAgencySlots(this.$store.state.profile.id);
    }
  },
  created() {
    this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  },
};
</script>
<style lang="scss">
@import url("https://cdn.jsdelivr.net/npm/v-stripe-elements@latest/dist/v-stripe-elements.min.css");
.object__submit {
  max-width: 255px;
  width: 100%;
}
#custom-button {
  display: block;
  margin: 20px auto 0 auto;
}

#card-error {
  color: red;
}

.__PrivateStripeElement {
  width: calc(60% - 7px) !important;
  float: left !important;
}

.loading{
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  color:#7f7f7f;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;

  &__icon {
    animation: 1s linear 0s normal none infinite running circle;
  }
  @keyframes circle {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
</style>
